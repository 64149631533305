<template>
  <WeLoading />
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Logout",
  methods: {
    ...mapActions("session", ["logout"]),
    doLogout() {
      this.logout({
        onSuccess: () => {},
        onFail: () => {},
        onError: (error) => {},
        onFinish: () => {
          this.$router.push("/login");
        },
      });
    },
  },
  mounted() {
    this.doLogout();
  },
};
</script>
